document.addEventListener("DOMContentLoaded", () => {

    // const swiperInfo = new Swiper(".industry-slider", {
    //     slidesPerView: "auto",
    //     // slidesPerView: 1,
    //     spaceBetween: 20,
    //     speed: 800,
    //     loop: true,
    //     centeredSlides: true,

    //     pagination: {
    //         el: ".swiper-pagination",
    //         type: "progressbar",
    //     },

    //     scrollbar: {
    //         draggable: true
    //     },

    //     breakpoints: {
    //         680: {
    //             spaceBetween: 30,
    //             centeredSlides: false,
    //             slidesPerView: 2
    //         },
    //         990: {
    //             spaceBetween: 30,
    //             centeredSlides: false,
    //             slidesPerView: "auto"
    //         },
    //         1600: {
    //             spaceBetween: 15,
    //             centeredSlides: false,
    //             slidesPerView: 6,
    //             loop: true,
    //         }
    //     },

    //     navigation: {
    //         nextEl: ".industry-next",
    //         prevEl: ".industry-prev",
    //     },
    // });

    $('.slider').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        dotsClass: "container pagination",

        responsive: [
            {
                breakpoint: 680,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    centerPadding: '20px'
                }
            },
            {
                breakpoint: 770,
                settings: {
                    centerMode: false,
                    slidesToShow: 3
                }
            }
        ]
    });

    const industryRows = document.querySelectorAll(".industry-row");
    industryRows.forEach(elem => {

        elem.addEventListener("click", () => elem.classList.toggle("active"));
    });

    const btnMenuOpen = document.querySelector(".btn-menu");
    const btnMenuClose = document.querySelector(".menu-mobile .close");
    const menuMobile = document.querySelector(".menu-mobile");

    btnMenuOpen.addEventListener("touchend", handleMenu);
    btnMenuClose.addEventListener("touchend", handleMenu);

    function handleMenu(event) {

        event.stopPropagation();
        menuMobile.classList.toggle("open");

        return false;
    }


    const industryItems = document.querySelectorAll(".industry-slider__item");

    industryItems.forEach(elem => {
        elem.addEventListener("click", function(event) {

            event.preventDefault();
            const targetId = this.dataset.industry;
            localStorage.setItem("activeElement", targetId);

            window.location.href = this.href;
        })
    })

    const activeElementId = localStorage.getItem("activeElement");

    if (activeElementId) {

        const activeElement = document.getElementById(activeElementId);

        if (activeElement) {

            activeElement.classList.add('active');
            localStorage.removeItem("activeElement");
        }
    }
    else {

        const industryRow = document.querySelector(".industry-row:first-child");

        industryRow && industryRow.classList.add("active");
    }
})